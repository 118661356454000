import type { WeekConfiguration } from '@ruokaboksi/api-client';

export type EmptyWeekConfiguration = Pick<
  WeekConfiguration,
  'additionalProducts' | 'recipes'
>;

const EMPTY_WEEK_CONFIGURATION: EmptyWeekConfiguration = {
  additionalProducts: [],
  recipes: [],
};

/**
 * Composable for getting information related to week configurations.
 */
export default function useWeekConfigurations() {
  const { deliveryDate } = useDeliveryWeeks();
  const { getWeekConfigurationsByDate } = useProductsApi();

  const {
    data: weekConfigurations,
    isFetched: isFetchedWeekConfigurations,
    isLoading: isLoadingWeekConfigurations,
  } = getWeekConfigurationsByDate(deliveryDate);

  const weekConfiguration = computed<
    WeekConfiguration | EmptyWeekConfiguration
  >(() =>
    weekConfigurations.value && weekConfigurations.value.length
      ? weekConfigurations.value[0]
      : EMPTY_WEEK_CONFIGURATION
  );

  return {
    isFetchedWeekConfigurations,
    isLoadingWeekConfigurations,
    weekConfiguration,
    weekConfigurations,
  };
}
