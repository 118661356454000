import type {
  AdditionalProduct,
  DeliveryWeek,
  LoyaltyLevel,
  Recipe,
} from '@ruokaboksi/api-client';
import { acceptHMRUpdate, defineStore } from 'pinia';
import {
  Week,
  MIN_LOYALTY_LEVEL,
  calculateDeliveryPlanPrice,
  Price,
  ZERO_PRICE,
} from '@ruokaboksi/utils';
import { ProductType } from '@/models';

/**
 * Store for managing selected products.
 * @example
 * ```ts
 * setup() {
 *   const selectProductsStore = useSelectProductsStore();
 *   const {
 *     ...helpers,
 *     setProductType // Required
 *   } = selectProductsStore;
 *
 *   const {
 *     ...variables
 *   } = storeToRefs(selectProductsStore);
 *
 *   onBeforeMount(() => {
 *     setProductType('recipe'); // or 'additional-product'
 *   });
 * }
 * ```
 * */
const useSelectProductsStore = defineStore('SelectProductsStore', () => {
  const hasProductSelectionChanged = ref(false);
  const isSaving = ref<boolean>(false);
  const products = ref<AdditionalProduct[] | Recipe[]>([]);
  const productType = ref<ProductType | null>(null);
  const selectedProductIds = ref<string[]>([]);

  const { currentCustomer } = useCurrentCustomer();
  const { subscriptionId, subscription } = useCustomerSubscriptions();
  const { setWeekProducts } = useDeliveriesApi();
  const {
    boxType,
    isLoadingDeliveryWeeks,
    maxRecipes,
    minRecipes,
    priceTiers,
    selectedDeliveryWeek,
    selectedDeliveryWeekCredit,
  } = useDeliveryWeeks();
  const { getAvailableDeliverySlots } = useDeliveriesApi();
  const noticeStore = useNoticeStore();
  const { isLoadingWeekConfigurations, weekConfiguration } =
    useWeekConfigurations();
  const { market } = useCurrentMarket();
  const { t } = useI18n();

  const isFinnishMarket = computed(() => market.value === 'FIN');

  const { data: deliverySlots } = getAvailableDeliverySlots(
    subscription.value?.defaultDeliverySlot
  );

  const router = useRouter();

  const isLoading = computed<boolean>(
    () => isLoadingDeliveryWeeks.value || isLoadingWeekConfigurations.value
  );

  const isAdditionalProduct = computed<boolean>(
    () => productType.value === 'additional-product'
  );

  const isRecipe = computed<boolean>(() => productType.value === 'recipe');

  /** List of additional product IDs for selected delivery week. */
  const additionalProductIds = computed<
    DeliveryWeek['additionalProducts'] | string[]
  >(() => {
    if (isAdditionalProduct.value) {
      return selectedProductIds.value;
    } else if (selectedDeliveryWeek.value?.additionalProducts.length) {
      return selectedDeliveryWeek.value?.additionalProducts;
    } else {
      return [];
    }
  });

  /** Total price for selected additional products. */
  const additionalProductsTotal = computed<number>(() => {
    let cents = 0;

    // Add up prices
    if (isAdditionalProduct.value) {
      cents += selectedProductIds.value.reduce((sum, id) => {
        // Check for frozenPrice
        const frozenProduct =
          selectedDeliveryWeek.value?.additionalProducts.find(
            (item) => item.id === id
          );
        if (frozenProduct) return sum + frozenProduct.price.grossPrice;
        // Get from weekConfig
        const product = weekConfiguration.value.additionalProducts.find(
          (item) => item.id === id
        );
        if (product && product.price) return sum + product.price;
        return 0;
      }, 0);
    } else {
      // Not selecting additionalProducts in this view,
      // We can just get the prices from deliveryWeek
      cents +=
        selectedDeliveryWeek.value?.additionalProducts.reduce(
          (sum, item) => sum + item.price.grossPrice,
          0
        ) || 0;
    }

    return cents;
  });

  /** List of recipe IDs for selected delivery week. */
  const recipeIds = computed<DeliveryWeek['recipes'] | string[]>(() => {
    if (isRecipe.value && selectedProductIds.value?.length) {
      return selectedProductIds.value;
    } else if (selectedDeliveryWeek.value?.recipes?.length) {
      return selectedDeliveryWeek.value?.recipes;
    } else {
      return [];
    }
  });

  /** Total price for delivery plan. */
  const totalPrice = computed<Price>(() => {
    const priceTier = priceTiers.value.find(
      (item) => item.recipeCount === recipeIds.value.length
    );

    if (!priceTier || !subscription.value || !selectedDeliveryWeek.value)
      return ZERO_PRICE;

    const currentlySelectedProducts = products.value.filter((product) =>
      selectedProductIds.value.includes(product.id)
    );

    const additionalProducts = isAdditionalProduct.value
      ? currentlySelectedProducts
      : selectedDeliveryWeek.value.additionalProducts;

    const deliverySlot =
      deliverySlots.value?.find(
        (slot) => slot.id === selectedDeliveryWeek.value?.deliverySlotId
      ) ?? subscription.value.defaultDeliverySlot;

    return calculateDeliveryPlanPrice({
      priceTier,
      recipes: isRecipe.value
        ? currentlySelectedProducts
        : selectedDeliveryWeek.value.recipes,
      additionals: additionalProducts.map((product) => {
        return {
          id: product.id,
          frozenProductPrice:
            'price' in product
              ? typeof product.price === 'object'
                ? product.price.grossPrice
                : product.price
              : 0,
        };
      }),
      campaign: selectedDeliveryWeek.value?.activeCampaign || null,
      loyaltyLevel:
        (currentCustomer.value?.loyaltyLevel as LoyaltyLevel) ??
        MIN_LOYALTY_LEVEL,
      deliverySlot,
      now: Week.fromPaymentDate(
        new Date(selectedDeliveryWeek.value?.paymentDate),
        deliverySlot
      ),
      credit: selectedDeliveryWeekCredit.value,
    });
  });

  /** Total price before discounts in cents. */
  const totalPreDiscounts = computed<number>(() => totalPrice.value.grossPrice);

  /** @returns `true` if discounted total differs from total price. */
  const hasDiscount = computed<boolean>(() =>
    totalPrice.value.hasPriceModifier()
  );

  /**
   * @returns `true` If selected recipes are within range of
   * min and max values.
   *
   * Always `true` for additional products.
   */
  const validSelection = computed<boolean>(() =>
    isRecipe.value
      ? selectedProductIds.value.length <= maxRecipes.value &&
        selectedProductIds.value.length >= minRecipes.value
      : true
  );

  /**
   * Initializes and sorts selected products based on
   * current product type and selected delivery week.
   */
  const initializeSelectedProducts = (): void => {
    selectedProductIds.value = isRecipe.value
      ? (selectedDeliveryWeek.value?.recipes?.map((item) => item.id) ?? [])
      : (selectedDeliveryWeek.value?.additionalProducts?.map(
          (item) => item.id
        ) ?? []);
    setHasProductSelectionChanged(false);
    sortProducts();
  };

  /** Adds a product ID to the current selection. */
  const selectProduct = (productId: string): void => {
    selectedProductIds.value.push(productId);
    setHasProductSelectionChanged(true);
  };

  const setHasProductSelectionChanged = (value: boolean): void => {
    hasProductSelectionChanged.value = !!value;
  };

  /** Sets the saving state for the store. */
  const setIsSaving = (value: boolean): void => {
    isSaving.value = value;
  };

  /** Sets the current product type for the store. */
  const setProductType = (value: ProductType | null): void => {
    productType.value = value;
    initializeSelectedProducts();
  };

  /** Removes a product from the current selection. */
  const unselectProduct = (productId: string): void => {
    selectedProductIds.value.splice(
      selectedProductIds.value.indexOf(productId),
      1
    );
    setHasProductSelectionChanged(true);
  };

  /**
   * Change the order of the products:
   * first the initially selected products
   */
  const sortProducts = (): void => {
    if (isRecipe.value) {
      const availableRecipes = weekConfiguration.value.recipes.filter(
        (recipe) => boxType.data.value?.size === recipe.size
      );

      const selectedRecipes: Recipe[] = selectedProductIds.value.map(
        (id) =>
          availableRecipes.find((recipes) => recipes?.id === id) ?? {
            ...fallbackRecipe,
            id,
            sku: id,
          }
      );

      const unselectedRecipes = availableRecipes.filter(
        (availableRecipe) => !selectedRecipes.includes(availableRecipe)
      );

      products.value = [...selectedRecipes, ...unselectedRecipes];
    } else {
      const availableProducts = weekConfiguration.value.additionalProducts;

      const selectedProducts: AdditionalProduct[] =
        selectedProductIds.value.map(
          (id) =>
            availableProducts.find((product) => product?.id === id) ?? {
              ...fallbackAdditionalProduct,
              id,
              price:
                selectedDeliveryWeek.value?.additionalProducts.find(
                  (product) => product?.id === id
                )?.price.grossPrice ?? 0,
              sku: id,
            }
        );

      const unselectedProducts = availableProducts.filter(
        (available) => !selectedProducts.includes(available)
      );
      products.value = [...selectedProducts, ...unselectedProducts];
    }
  };

  /** Saves product changes and shows a notice when done. */
  const saveProductChanges = async (): Promise<void> => {
    if (!subscriptionId.value || !selectedDeliveryWeek.value) return;
    const recipeIds = isRecipe.value
      ? selectedProductIds.value
      : selectedDeliveryWeek.value.recipes?.map((i) => i.id) || [];
    const additionalProductIds = isAdditionalProduct.value
      ? selectedProductIds.value
      : selectedDeliveryWeek.value.additionalProducts?.map((i) => i.id) || [];
    const productIds = {
      recipes: recipeIds,
      additionalProducts: additionalProductIds,
    };
    setIsSaving(true);
    try {
      await setWeekProducts(
        productIds,
        subscriptionId.value,
        selectedDeliveryWeek.value?.paymentDate
      );
      setIsSaving(false);
      setHasProductSelectionChanged(false);

      noticeStore.addNotice({
        text: t('select_products.delivery_updated'),
        type: 'success',
      });

      if (
        router.currentRoute.value.path !== '/additional-products' &&
        isFinnishMarket.value
      ) {
        router.push({
          path: `/additional-products`,
          query: {
            selectedWeek: selectedDeliveryWeek.value?.weekString,
            redirectedFrom: 'recipes',
          },
        });
      } else {
        router.replace({
          query: {
            ...router.currentRoute.value.query,
            redirectedFrom: undefined,
          },
        });

        sortProducts();
      }
    } catch (error) {
      noticeStore.addNotice({
        text: `Toimituksen päivitys epäonnistui. Mikäli virhe toistuu, lataa sivu uudestaan.`,
        type: 'caution',
      });
    }
  };

  initializeSelectedProducts();

  watch([selectedDeliveryWeek, productType], initializeSelectedProducts);
  watch(weekConfiguration, sortProducts);

  return {
    additionalProductIds,
    additionalProductsTotal,
    hasDiscount,
    hasProductSelectionChanged,
    initializeSelectedProducts,
    isAdditionalProduct,
    isLoading,
    isSaving,
    isRecipe,
    productType,
    products,
    recipeIds,
    saveProductChanges,
    selectProduct,
    selectedProductIds,
    setProductType,
    totalPreDiscounts,
    totalPrice,
    unselectProduct,
    validSelection,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useSelectProductsStore, import.meta.hot)
  );
}

export default useSelectProductsStore;
